<template>
	<div>
		<!-- 头部logo -->
		<headLogo @navigation="navigation"></headLogo>
		
		<!-- 头部导航 -->
		<navigation :headTitle="headTitle" :headImg="headImg" style="margin-top:96px;"></navigation>
		
		<div class="education">
			<div @click="details(list)" class="education-bigbox" v-if="notData">
				<el-row class="education-imgbox">
					<el-image :src="pictureUrl" class="education-img"></el-image>
				</el-row>
				<el-row class="educationNobtn-blankbox"></el-row>
				<el-image src="/index/vertical.png" class="educationverticalNobtn-img"></el-image>
				<el-row class="educationNobtn-databox">
					<el-col :span="22" :offset="1">
						<el-row class="education-title">
							{{list.title}}
						</el-row>
						<el-row v-html="list.bodyContent" class="education-message">
							{{list.bodyContent}}
						</el-row>
					</el-col>
				</el-row>
			</div>
			<el-row v-else>
				<el-row>
					<el-image src="/index/nodata.png" class="error-img"></el-image>
				</el-row>
				<el-row class="error-message">
					Empty data
				</el-row>
			</el-row>
			
			<el-row class="education-box">
				<el-col :span="12" :class="[this.value==1?'educatin-yes':'educatin-no']">
					<div @click="educatin">
						EDUCATION
					</div>
				</el-col>
				<el-col :span="12" :class="[this.value==2?'outreach-yes':'outreach-no']">
					<div @click="outreach">
						OUTREACH
					</div>
				</el-col>
			</el-row>
			<el-row class="newsPc">
				<el-row v-if="notEducatinData">
					<el-row>
						<el-col :span="8" v-for="(item,id) in ListData" :key="id" class="newsPublicityPc-dataall">
							<div class="news-data" @click="details(item)">
								<el-row class="news-data-extend">
									<img :src="newsNotLoadImage" :onload="onLoadImg(item.pictureUrl)" class="newsdata-img"></img>
									<el-image class="newsdata-coverageimg" src="/home/newCoverage.png"></el-image>
									<p class="newsdata-threeLogoimg"></p>
									<div class="newsdata-titleMessage">
										<p class="newsdata-title">{{item.title}}</p>
										<p class="newsdata-date">
											<span>{{item.month}}</span>
											<span>{{item.time}},</span>
											<span>{{item.year}}</span>
										</p>
									</div>
								</el-row>
							</div>
						</el-col>
					</el-row>
					<!-- 按钮 -->
					<el-row>
						<button v-if="loading" class="education-btn" @click="load">Load More</button>
					</el-row>
				</el-row>
				<el-row v-else>
					<el-row>
						<el-image src="/index/nodata.png" class="error-img"></el-image>
					</el-row>
					<el-row class="error-message">
						Empty data
					</el-row>
				</el-row>
			</el-row>
		</div>
		
		<!-- 菜单导航 -->
		<meauDialog :meauValue="navigationvalue" @meauFn="menu"></meauDialog>
		
		<!-- 底部导航 -->
		<bottomNavigation></bottomNavigation>
	</div>
</template>

<script>
// 图片查询接口
import { selectPictureListById , selectContentListById } from '@/api/index.js'

	// 头部组件
	import headLogo from '@/components/headLogo.vue'
	// 头部导航
	import navigation from '@/components/navigation.vue'
	// 底部导航
	import bottomNavigation from '@/components/bottomNavigation.vue'
	// 菜单导航
	import meauDialog from '@/components/meauDialog.vue'
	export default
	{
		data()
		{
			return{
				// 头部导航标题
				headTitle:'EDUCATION&OUTREACH',
				// 头部导航图片
				headImg:'/index/education.png',
				value:1,
				
				// 加载按钮
				loading:true,
				// 默认条数
				count:12,
				
				// 导航值
				navigationvalue:false,
				
				list:{},
				newList:[],
				moduleType: 'MODULE_EDUCATION',
				pictureUrl:'',
				
				notData:true,
				notEducatinData:true,
				
				eduNotLoadImage: "/PCimage/1100_480.jpg", //产品图片未加载出来
				newsNotLoadImage: "/PCimage/370_424.jpg", //新闻图片未加载出来
			}
		},
		components:
		{
			headLogo,
			navigation,
			bottomNavigation,
			meauDialog
		},
		computed:
		{
			// 计算属性
			ListData()
			{
				let data=[];
				if(this.newList.length>12)
				{
					data=this.newList.slice(0,this.count)
					return data;
				}
				else
				{
					data=this.newList;
					this.loading=false;
					return data;
				}
			}
		},
		created()
		{
			this.getList();
			this.getEducatinList();
		},
		methods:
		{
			// 头部传来的数据
			navigation(v)
			{
				this.navigationvalue=v;
			},
			// 遮罩层传回来的数据
			menu(v)
			{
				this.navigationvalue=v;
			},
			
			// 获取数据
			getList()
			{
				selectContentListById(
				{
					chnanelType: 1,
					isIndex: 0,
					moduleType: 'MODULE_EDU'
				})
				.then((response)=>
				{
					if(response.data.data=='')
					{
						this.notData=false;
					}
					else
					{
						this.notData=true;
						this.list=response.data.data[0];
						this.pictureUrl=response.data.data[0].pictureUrl;
					}
				})
			},
			getEducatinList()
			{
				selectContentListById(
				{
				  chnanelType: 1,
				  isIndex: 0,
				  moduleType: this.moduleType
				})
				.then((response)=>
				{
					if(response.data.data=='')
					{
						this.notEducatinData=false;
					}
					else
					{
						this.loading=true;
						this.notEducatinData=true;
						this.newList=response.data.data;
						this.newList.map((v)=>
						{
							this.time(v)
						})
					}
				})
			},
			// 时间
			time(v)
			{
				v.pulish=v.publish.split('-')
				if(v.pulish[1]=='01')
				{
					v.pulish[1]='JAN'
				}
				else if(v.pulish[1]=='02')
				{
					v.pulish[1]='FEB'
				}
				else if(v.pulish[1]=='03')
				{
					v.pulish[1]='MAR'
				}
				else if(v.pulish[1]=='04')
				{
					v.pulish[1]='APR'
				}
				else if(v.pulish[1]=='05')
				{
					v.pulish[1]='MAY'
				}
				else if(v.pulish[1]=='06')
				{
					v.pulish[1]='JUN'
				}
				else if(v.pulish[1]=='07')
				{
					v.pulish[1]='JUL'
				}
				else if(v.pulish[1]=='08')
				{
					v.pulish[1]='AUG'
				}
				else if(v.pulish[1]=='09')
				{
					v.pulish[1]='SEP'
				}
				else if(v.pulish[1]=='10')
				{
					v.pulish[1]='OCT'
				}
				else if(v.pulish[1]=='11')
				{
					v.pulish[1]='NOV'
				}
				else if(v.pulish[1]=='12')
				{
					v.pulish[1]='DEC'
				}
				v.year=v.pulish[0];
				v.month=v.pulish[1];
				v.time=v.pulish[2];
			},
			// 加载更多
			load()
			{
				this.count+=12;
				console.log(this.count,this.newList.length)
				if(this.count>=this.newList.length)
				{
					this.loading=false
				}
			},
			
			// 跳转详情
			details(item)
			{
				localStorage.setItem('Details',JSON.stringify(item));
				this.$router.push(
				{
					path:'/noticesDetails',
					query:
					{
						item:item,
						headTitle:'EDUCATION&OUTREACH',
						headImg:'/index/education.png'
					}
				})
			},
						
			educatin()
			{
				this.value=1;
				this.count=12;
				this.moduleType='MODULE_EDUCATION',
				this.getEducatinList();
			},
			outreach()
			{
				this.value=2;
				this.count=12;
				this.moduleType='MODULE_OUTREACH',
				this.getEducatinList();
			},
			// 图片加载完成时触发
			onLoadImg(imgSrc) 
			{
				return 'this.src='+'"'+imgSrc+'";this.οnlοad=null';
			},
		}
	}
</script>

<style scoped>
	@import '../assets/newsBox.css';
	.education{
		width: 1050px;
		margin: 0 auto;
	}
	.education-box{
		width: 60%;
		height: 50px;
		border: solid 2px #ccc;
		margin-left: 20%;
		border-radius: 50px;
		line-height: 50px;
		margin-top: 50px;
		font-size: 16px;
		color: #505050;
	}
	.educatin-yes{
		height: 46px;
		background-color: #FCD002;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
	}
	.educatin-no{
		height: 46px;
		background-color: #F0EDF1;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
	}
	.outreach-yes{
		height: 46px;
		background-color: #FCD002;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
	}
	.outreach-no{
		height: 46px;
		background-color: #F0EDF1;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
	}
	.education-btn{
		width: 400px;
		height: 50px;
		border: none;
		border-radius: 50px;
		margin-top: 20px;
		background-color: #FCD002;
		color: #505050;
		font-size: 24px;
		color: #505050;
	}
	.education-bigbox{
		width:100%;
		color:#505050;
		margin-top: 20px;
	} 
	.education-imgbox{ 
		width: 90%; 
		border-radius: 20px;
		z-index: 34;
	} 
	.education-img{
		width: 880px;
		/* width: 1100px; */
		max-height: 495px;
		border-radius: 20px;
		float: left;
		z-index: 34;
	}
	.education-title{
		font-size: 25px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-size: 30px;
		color: #505050;
	}
	.education-message{
		margin-top: 20px;
		line-height: 30px;
		width: 100%;
		bottom:10px;
		font-size: 20px;
		word-wrap:break-word;
		white-space: pre-wrap;
		font-size: 14px;
		color: #505050;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 6;
		-webkit-box-orient: vertical;
	}
	.education-message::v-deep img{
		max-width: 100%;
	}
	/* 空白 */
	.educationNobtn-blankbox{
		width: 940px;
		height: 290px;
		background-color: #F6F5F8;
		margin-top: -288px;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		margin-left: 71px;
	}
	/* 没有按钮 */
	.educationNobtn-databox{
		width:940px;
		overflow: hidden;
		background-color: #F6F5F8;
		padding: 10px 2% 0px 5%;
		text-align: left;
		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;
		margin-left: 71px;
	}
	.educationverticalNobtn-img{
		width: 6px;
		height: 170px;
		z-index: 999;
		margin-top: -30px;
		margin-left: -410px;
		position: absolute;
	}	
	.error-img{
		width: 300px;
		height: 200px;
		margin-top: 30px;
	}
	.error-message{
		margin-top: 30px;
		font-size: 20px;
		color: #3B519D;
		margin-bottom: 50px;
	}
</style>